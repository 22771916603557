<template>
  <main>
    <RouterView />
  </main>
</template>

<script>

export default {
  name: 'App',
  mounted() {
    let plausibleScript = document.createElement('script')
    plausibleScript.setAttribute('src', 'https://plausible.regio-cloud.net/js/script.js')
    plausibleScript.setAttribute('data-domain', 'widgets.ilsede.cim-lora.de')
    plausibleScript.setAttribute('defer', 'defer')
    document.head.appendChild(plausibleScript)
  },
}

</script>

<style></style>
