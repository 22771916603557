const { default: axios } = require('axios');
const YAML = require('yaml');

let config = null;

const getConfig = async () => {
    if (config !== null) {
        return config;
    }
    try {
        const configFile = await axios.get('/widgets.yaml');
        const yaml = YAML.parse(configFile.data);
        config = yaml;
        return yaml;
    }
    catch (e) {
        console.error(e);
        return null;
    }
}


const getComponentConfig = async (componentName) => {
    const config = await getConfig();
    if (config === null) {
        return undefined;
    }
    for (const widget of config.widgets) {
        if (widget.class_name === componentName) {
            return widget;
        }
    }
    return undefined;
}

export { getConfig, getComponentConfig };