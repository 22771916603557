import { createRouter, createWebHistory } from 'vue-router'

import { getConfig } from './config/loader';

const routes = []

const config = await getConfig();

if (config !== null) {
    for (const widget of config.widgets) {
        routes.push({
            path: `/${widget.path}`,
            name: widget.class_name,
            component: () => import(`./widgets/${widget.class_name}.vue`),
        })
    }
}


const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router